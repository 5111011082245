<template>
  <div class="container-fluid">
    <nav class="navbar navbar-default navbar-static-top">
      <div class="container">
        <div class="navbar-header">
          <button
            type="button"
            class="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#navbar"
            aria-expanded="false"
            aria-controls="navbar"
          >
            <span class="sr-only"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a class="navbar-brand" @click="toPage('index')">
            <svg-icon icon-class="new-logo-white" class-name="icon-logo" />
          </a>
        </div>
        <div id="navbar" class="navbar-collapse collapse">
          <ul class="nav navbar-nav navbar-right">
            <li class=""><a @click="toPage('index')">首页</a></li>
            <li class="">
              <a @click="goTo(0)" style="color: rgba(255, 255, 255, 0.6)"
                >免费课</a
              >
            </li>
            <li class="">
              <a @click="goTo(1)" style="color: rgba(255, 255, 255, 0.6)"
                >高阶课</a
              >
            </li>
            <!-- <li><a href="">下载客户端</a></li>
          <li><a href="">精彩文章</a></li> -->
            <!-- <li><a @click="toPage(3)">关于我们</a></li> -->
            <li>
              <a @click="toLogin" class="navbar-nav-btn">{{ loginText }}</a>
            </li>
          </ul>
        </div>
        <!--/.nav-collapse -->
      </div>
    </nav>
    <div class="container">
      <div class="box" style="color: #ebebeb">
        <div
          style="
            font-weight: 900;
            font-size: 48px;
            line-height: 67px;
            text-align: center;
          "
        >
          关于我们
        </div>
        <div
          style="
            margin-top: 30px;
            padding: 40px;
            background: #1a1a1a;
            border-radius: 8px;
          "
        >
          <div style="font-size: 40px; line-height: 56px; text-align: center">
            我们是谁
          </div>
          <div
            style="
              margin-top: 25px;
              font-size: 18px;
              line-height: 40px;
              text-align: justify;
              text-indent: 2em;
            "
          >
            <p>
              我们是逑知（深圳）教育科技有限公司，成立于2021年8月，致力于研究底层学习规律，是领域知名的学习方法咨询服务机构。
            </p>
            <p>
              我们为学校提供学习力提升整体解决方案，帮企业打造学习型组织，帮个人学会科学地学习。
            </p>
            <p>
              公司创始团队经过长期的研究探索，研发出以“模型树”为核心的学习方法体系，旗下拥有“思维有了模型”、“学习有了方法”、“逑知学研院”等多个领域知名的自媒体品牌，受益学员数万名，一路走来，感谢朋友们的信任、陪伴和支持。
            </p>
            <p>
              模型树是逑知教育旗下一款专业的学习工具软件，其主要定位是解决爱学习者在工作和学习中的方法和效率问题，梳理学习思路，协作学习，进行内容创作和任务管理等。
            </p>
          </div>
        </div>
        <div
          style="
            margin-top: 100px;
            padding: 40px;
            background: #1a1a1a;
            border-radius: 8px;
            text-align: center;
          "
        >
          <div style="font-size: 40px; line-height: 56px">我们的使命</div>
          <div style="margin-top: 25px; font-size: 18px; line-height: 40px">
            帮助1亿国人科学学习，助力中国引领人类进入知识革命时代。
          </div>
        </div>
        <div
          style="
            margin-top: 70px;
            padding: 40px;
            background: #1a1a1a;
            border-radius: 8px;
          "
        >
          <div style="font-size: 40px; line-height: 56px; text-align: center">
            联系我们
          </div>
          <div style="margin-top: 15px; font-size: 20px; line-height: 42px">
            公司地址：广东省深圳市南山区粤海街道高新区社区高新南七道018号高新工业村R3-B栋01层211<br />
            用户QQ群：947624236<br />
            联系微信：siweimoxing003
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid box3">
    <div class="container box4">
      <div class="row">
        <div class="col-md-4">
          <div class="box4-title">思维有了模型</div>
          <div class="box4-info" @click="toPage('about')">关于我们</div>
          <div class="box4-info">帮助中心</div>
          <div class="box4-info" @click="toPage('agreement')">用户协议</div>
          <div class="box4-info" @click="toPage('privacy')">隐私声明</div>
        </div>
        <div class="col-md-4">
          <div class="box4-title">联系客服</div>
          <div class="box4-info" style="font-size: 14px">
            扫码联系微信在线客服
          </div>
          <div class="block" style="margin-top: 8px">
            <el-image src="/pc/wx-kf.png" class="box4-img"></el-image>
            <span class="demonstration">(周一至周五9：30-16：30)</span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="box4-title">微信公众号</div>
          <div class="box4-info" style="font-size: 14px">
            想要了解更多精彩文章，欢迎关注以下微信公众号
          </div>
          <div class="row" style="margin-top: 8px">
            <div class="col-md-4">
              <div class="block">
                <el-image src="/pc/wx-gzh-1.png" class="box4-img"></el-image>
                <span class="demonstration">思维有了模型</span>
              </div>
            </div>
            <div class="col-md-4">
              <div class="block">
                <el-image src="/pc/wx-gzh-2.png" class="box4-img"></el-image>
                <span class="demonstration">学习有了方法</span>
              </div>
            </div>
            <div class="col-md-4">
              <div class="block">
                <el-image src="/pc/wx-gzh-3.png" class="box4-img"></el-image>
                <span class="demonstration">逑知学研院</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="divider"></div>
    <div class="copyright">
      Copyright ©2021 逑知（深圳）教育科技有限公司<br /><a
        href="https://beian.miit.gov.cn/"
        target="_blank"
        >粤ICP备2021136613号</a
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeIndex: "0",
      isLogin: false,
      loginText: "注册/登录",
    };
  },
  inject: ["reload"],
  created() {
    this.isLogin = localStorage.getItem("isLogin");
    if (this.isLogin == "true") {
      this.loginText = "已登录";
    } else {
      this.loginText = "注册/登录";
    }
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "scriptjq";
    script.src =
      "https://cdn.bootcdn.net/ajax/libs/jquery/1.12.4/jquery.min.js";
    document.body.appendChild(script);
  },
  mounted() {
    let script2 = document.createElement("script");
    script2.type = "text/javascript";
    script2.id = "scriptboot";
    script2.src =
      "https://cdn.bootcdn.net/ajax/libs/twitter-bootstrap/3.4.1/js/bootstrap.min.js";
    setTimeout(() => {
      document.body.appendChild(script2);
    }, 1000);
  },
  beforeUnmount() {
    document.getElementById("scriptjq").remove();
    if (document.getElementById("scriptboot"))
      document.getElementById("scriptboot").remove();
  },
  methods: {
    refresh() {
      this.reload();
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    toPage(page = "index") {
      this.$router.push("/" + page).then(() => {
        window.scrollTo(0, 0);
      });
      // if (i == 0) {
      //   this.$router.push("/");
      // } else if (i == 1) {
      //   this.$router.push("/download");
      // } else if (i == 2) {
      //   this.$router.push("/articles");
      // } else if (i == 3) {
      //   this.$router.push("/about");
      // }
    },
    toLogin() {
      if (this.isLogin == "true") {
        this.$router.push("/article");
      } else {
        this.$router.push("/login");
      }
    },
    goTo(i) {
      if (i == 0) {
        window.open("https://ejydt.xetslk.com/s/48w2yM", "_blank");
      } else if (i == 1) {
        window.open("https://ejydt.xetslk.com/s/3SPmpx", "_blank");
      }
    },
  },
};
</script>
<style src="@/assets/bootstrap/css/bootstrap.min.css" scoped></style>
<style scoped>
.container-fluid {
  background: #000;
  padding: 0;
}
.navbar-default {
  background: rgba(0, 0, 0, 0.6);
  border: 0;
  margin-top: 0;
  margin-bottom: 0;
  height: 80px;
}
.navbar-brand {
  padding: 0;
  height: 80px;
}
.navbar-brand svg {
  float: left;
  margin-top: 16px;
  height: 48px;
  border-radius: 8px;
  width: 228px;
}
.navbar-brand span {
  float: left;
  line-height: 50px;
  font-size: 20px;
  color: #1380ff;
}
.navbar-nav .active {
  color: #333333;
  font-weight: 900;
}
.navbar-nav-btn {
  border: 0;
  background: #fff !important;
  border-radius: 22px;
  height: 40px;
  width: 111px;
  line-height: 40px !important;
  margin: 20px 0 20px 20px !important;
  padding: 0 !important;
  color: #000 !important;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
.navbar-toggle {
  margin-top: 23px;
  border: 0;
  color: #fff;
  .icon-bar {
    background-color: #fff;
  }
}
.navbar-toggle:hover {
  border: 0;
  background-color: transparent !important;
  color: #fff !important;
}
.center {
  text-align: center;
}
.mac-box {
  width: 100%;
}
.mac-box .mac-img {
  width: 100%;
  border-radius: 12px;
}
.title,
.title2,
.title3,
.title4,
.title5,
.title6,
.title7,
.title8 {
  font-weight: 900;
  font-size: calc(100vw * 48 / 1920);
  line-height: calc(100vw * 70 / 1920);
  letter-spacing: 4px;
  background: linear-gradient(
    180deg,
    #fff 27.86%,
    rgba(255, 255, 255, 0) 224.29%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: calc(100vw * 40 / 1920);
}
.title2 {
  background: linear-gradient(271.14deg, #c55656 1.56%, #e696a4 93.05%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.title3 {
  background: linear-gradient(271.14deg, #b89d00 -8.95%, #ffec82 109.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.title4 {
  background: linear-gradient(271.14deg, #72b800 -8.95%, #f0ffdc 109.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.title5 {
  background: linear-gradient(271.14deg, #00b8a2 -8.95%, #97ffbb 109.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.title6 {
  background: linear-gradient(271.14deg, #008cb8 -8.95%, #82f0ff 109.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.title7 {
  background: linear-gradient(271.14deg, #7c5bff -8.95%, #82d2ff 109.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.title8 {
  font-size: calc(100vw * 72 / 1920);
  line-height: calc(100vw * 108 / 1920);
  background: linear-gradient(271.14deg, #ac52b6 1.56%, #3ed4dd 109.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: center;
  margin-bottom: calc(100vw * 40 / 1920);
}
@media (min-width: 992px) {
  .box1 .title {
    margin-top: 270px;
  }
}
.info {
  font-weight: 500;
  font-size: calc(100vw * 24 / 1920);
  line-height: calc(100vw * 64 / 1920);
  color: #fff;
  margin-top: calc(100vw * 40 / 1920);
}
.box1-img {
  max-width: 100%;
  margin: 20px auto 90px;
}
.box2 {
  background: #f8f8f8;
}
.box3 {
  background: #000;
}
.box3-title {
  margin: 125px auto 40px auto;
  position: relative;
  width: 300px;
  height: 60px;
  font-size: 48px;
  font-weight: bold;
  color: #333333;
}
.box3-title-img {
  position: absolute;
  width: 66px;
  height: 66px;
  top: -30px;
  left: -60px;
}
.box3-title-img img {
  width: 100%;
}
.box3-info {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
}
.btn1 {
  height: 45px;
  width: 160px;
  background: #fff;
  border-radius: 56px;
  margin-top: 64px;
  border: 0;
  color: #000;
  line-height: 30px;
  font-size: 20px;
  font-weight: 700;
}
.btn1:active,
.btn1:hover {
  border: 0;
  color: #000;
}
.btn2 {
  width: 150px;
  background: #1380ff;
  border-radius: 10px;
  border: 0;
  margin: 0 10px;
}
.box4 {
  margin-bottom: 40px;
  color: #cde7ff;
}
.box4-title {
  font-size: 24px;
  font-weight: 400;
}
.box4-info {
  margin-top: 15px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}
.block {
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
}
.demonstration {
  display: block;
  font-size: 12px;
  text-align: center;
}
.box4-img {
  max-width: 85px;
}
.copyright {
  margin: 15px auto 35px;
  font-size: 12px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 1px;
  color: #666666;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
  border-radius: 10px;
  color: #ebebeb;
}
.navbar-default .navbar-nav > li > a,
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover {
  color: #ebebeb;
}
.navbar-right li {
  cursor: pointer;
}
.navbar-nav > li > a {
  margin: 15px 20px;
}
@media screen and (min-width: 990px) {
  .title,
  .title2,
  .title3,
  .title4,
  .title5,
  .title6,
  .title7,
  .title8 {
    font-size: 48px;
    line-height: 48px;
    margin-top: 56px;
  }
  .title8 {
    font-size: 72px;
    line-height: 108px;
    margin-bottom: 40px;
  }
  .info {
    font-size: 32px;
    line-height: 46px;
    margin-top: 30px;
  }
  .box1-row {
    position: relative;
    height: 800px;
  }
  .box1 {
    top: 0;
    right: 0;
  }
}

/* @media (max-width: 1280px) and (min-width: 990px) {
  .title,
  .title2,
  .title3,
  .title4,
  .title5,
  .title6,
  .title7,
  .title8 {
    font-size: 40px;
    line-height: 40px;
    margin-top: 40px;
  }
  .info {
    font-size: 20px;
    line-height: 54px;
    margin-top: 30px;
  }
} */
.top-box {
  max-width: 1920px !important;
  margin: 0 auto;
  height: 800px !important;
  background-image: url("https://moxingshu.oss-cn-shanghai.aliyuncs.com/image/a56292b263cf49e38086b78ba597ad72.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.navbar {
  min-height: 80px;
}
.box1-i {
  width: 55%;
  height: 390px;
  display: block;
}
@media (max-width: 990px) {
  .title,
  .title2,
  .title3,
  .title4,
  .title5,
  .title6,
  .title7,
  .title8 {
    font-size: 38px;
    line-height: 38px;
    margin-top: 40px;
  }
  .info {
    font-size: 24px;
    line-height: 24px;
    margin-top: 40px;
  }
  .box1-i {
    width: 30%;
    float: left;
  }
  .box1 {
    float: right;
    .title {
      margin-top: 340px;
    }
  }
}
@media (max-width: 768px) {
  .navbar-brand svg {
    margin-top: 28px;
    height: 24px;
    width: 114px;
  }
  .top-box {
    width: 100% !important;
    background-image: url("https://moxingshu.oss-cn-shanghai.aliyuncs.com/image/ebfcf6598c1245b8983fc86935e555a1.png");
  }
  .box1-i {
    float: unset;
    height: 460px;
  }
  .box1 {
    float: unset;
    text-align: center;
    .title {
      margin-top: 10px;
      font-size: 32px;
      line-height: 32px;
    }
    .info {
      font-size: 20px;
      margin-top: 20px;
    }
    .btn1 {
      margin-top: 40px;
    }
  }
}
</style>
